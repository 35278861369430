<template>
  <div style="padding-bottom: 10px">
    <a-row :gutter="5" align="middle">
      <a-col> 坐标：</a-col>
      <a-col>
        <a-input
          v-model:value="coordinateValue"
          :disabled="true"
          style="
            background-color: #fff;
            color: #000;
            cursor: auto;
            width: 320px;
          "
        />
      </a-col>
      <a-col>
        <a-button type="primary" @click="doneClick">确定</a-button>
      </a-col>
    </a-row>
  </div>

  <div id="chinaChart" style="width: 100%; height: 350px;">
  </div>
  <div
      :style="`display: ${popupPostion.display}; top: ${popupPostion.top}; left: ${popupPostion.left}`"
      v-if="details"
      style="
        font-size: 22px;
        cursor: pointer;
        color: blue;
        position: absolute;
        z-index: 20000;
      "
    >
      <EnvironmentOutlined />
    </div>

  <!-- <div class="contentPopup">
  </div> -->
</template>

<script>
import chinaJson from './100000_full.json'
import * as echarts from 'echarts'
import chinaOptions from './mapOption.js'

import { EnvironmentOutlined } from '@ant-design/icons-vue'
var chinaChart
export default {
  name: 'chinaMap',
  emits: ['done'],
  props: {
    // 修改回显的数据
    position: {},
    warehouseList: {
      type: Array,
      default: () => {
        return []
      }
    },
    lnglat: [],
    showMarker: {
      type: Boolean,
      default: true
    }
  },
  components: {
    EnvironmentOutlined
  },
  data() {
    return {
      coordinateValue: this.lnglat,
      longiValue: [], // 经度
      latiValue: [], // 纬度
      // iconShow: true, // 图标显示隐藏
      popupPostion: { top: '60px', left: '100px', display: 'none' },
      details: false,
      zoom: 0,
      inMap: false,
      modalData: {},
      chinaChart: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      console.log('coordinateValue', this.coordinateValue)
      this.init()
    })
  },
  methods: {
    init() {
      console.log('init')
      chinaChart = echarts.init(document.getElementById('chinaChart'))
      const that = this
      // 初始化福建地图
      echarts.registerMap('china', chinaJson, {})
      // 获取dom节点
      // eslint-disable-next-line prefer-const
      this.restMapData(true)
      chinaChart.on('mouseover', function (target) {})
      chinaChart.on('mousemove', function (target) {
        // console.log('mousemove', target)
        // if (target.name === '厦门市') {
        //   const option = chinaChart.getOption()
        //   if (option.geo[0].zoom < 100) {
        //     option.geo[0].zoom = 100
        //     chinaChart.setOption(option)
        //   }
        // }
        if (
          target.componentType === 'series' &&
          target.data &&
          target.data.code
        ) {
          // const option = chinaChart.getOption()
          // option.geo[0].center = [target.data.value[0], target.data.value[1]]
          // chinaChart.setOption(option)
          // that.details = true
          // that.popupPostion.display = 'block'
          // that.popupPostion.left = target.event.offsetX - 200 + 'px'
          // that.popupPostion.top = target.event.offsetY - 200 + 'px'
          that.modalData = target.data
          that.$emit('updateKey', target.data)
        }
      })
      chinaChart.on('mouseout', function (target) {
        that.inMap = false
      })
      chinaChart.getZr().on('click', function (params) {
        const pixelPoint = [params.offsetX, params.offsetY]
        const point = chinaChart.convertFromPixel({ geoIndex: 0 }, pixelPoint)
        that.popupPostion.display = 'none'
        that.popupPostion.left = params.event.offsetX + 12 + 'px'
        that.popupPostion.top = params.event.offsetY + 90 + 'px'
        console.log('组件point', point)
        that.coordinateValue = point[0] + ' , ' + point[1]
        that.longiValue = point[0]
        that.latiValue = point[1]
        console.log('组件coordinateValue', that.coordinateValue)
      })
      chinaChart.on('georoam', function (params) {
        that.zoom = chinaChart.getOption().geo[0].zoom
        chinaChart.center = chinaChart.getOption().geo[0].center
      })
    },
    contentMouseover() {
      this.inMap = true
    },
    changeZoom() {
      chinaOptions.changeZoom(100)
      this.resetMapOptions()
    },
    resetMapOptions() {
      chinaChart.setOption(chinaOptions.options(), true)
    },
    restMapData(show) {
      if (show) {
        chinaOptions.iconData(this.coordinateValue)
      } else {
        chinaOptions.initData([])
      }
      this.resetMapOptions()
    },
    resize() {
      chinaChart.resize()
    },
    doneClick() {
      console.log('点击了doneClick')
      this.$emit('done', this.longiValue, this.latiValue)
    }
  }
}
</script>

<style lang="less" scoped>
.contentPopup {
  position: absolute;
  z-index: 1001;
}
</style>
